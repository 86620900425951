<template>
  <modal v-on="$attrs">
    <template #body>
      <div class="he-ui-success-modal-image mb-4">
        <img
          src="../assets/patient-success.png"
          alt="image-success" />
      </div>
      <p class="he-ui-success-modal-message">
        {{ !isNewPatient
        ? $t('main.patient_idetified')
        : (!isAlreadyRegistered ? $t('main.patient_registered') : $t('main.patient_already_exists') ) }}</p>
      <span class="he-ui-success-modal-hhid">HHID: {{ patientId }}</span>
      <Button
        v-show="showButton"
        template="blue"
        rounded
        uppercase
        class="mt-4"
        :onClick="onClicked"
        :text="message"
      />
    </template>
  </modal>
</template>

<script>
import Button from '@/components/MainButton.vue'
import Modal from './Modal.vue'

export default {
  components: {
    Button,
    Modal
  },
  props: {
    patientId: {
      type: String,
      default: ''
    },
    onClicked: {
      type: Function,
      default: null
    },
    message: {
      type: String,
      default: ''
    },
    showButton: {
      type: Boolean,
      default: false
    },
    isNewPatient: {
      type: Boolean,
      default: false
    },
    isAlreadyRegistered: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-success-modal-image{
  width: 140px;
  height: 140px;
   img {
    max-width: 100%;
   }
}
.he-ui-success-modal-message {
  color: $grey-dark-color;
  font-size: 1.5rem;
}
.he-ui-success-modal-hhid {
  font-weight: bold;
  font-size: 1.8rem;
}
</style>
