<template>
    <Header
      :headerText="$t('main.identification')"
      :goBack="goBack"
    />
    <DefaultContainer
      col1='col-5'
      col2='col-7'
    >
      <template #col1>
        <section class="col1 d-flex flex-column align-items-center justify-content-center h-100">
          <div
            v-if="!isImageCaptured"
            class="he-ui-font-regular mb-3"
          >
              {{ $t('main.identify_patient_scanning_title') }}:
          </div>
          <info-box
            class="he-ui-info-box"
            status="indications"
            :hand="HANDS.RIGHT.index"
            :finger="FINGERS_RIGHT_HAND.INDEXFINGER.index"
          />
          <Hand
            :selectedHand="HANDS.RIGHT.index"
            :selectedFinger="FINGERS_RIGHT_HAND.INDEXFINGER.index"
            :showNumberOfFingers="false"
            :showAllFingerprints="false"
            :isNewPatient="false"
          />
        </section>
      </template>
      <template #col2>
        <section class="col2 d-flex flex-column he-ui-scanning-section">
          <scanning-results
            ref="scanningResultsComponent"
            class="he-ui-scan-main-finger-wrapper"
            :status="status"
            :hand="HANDS.RIGHT.index"
            :finger="FINGERS_RIGHT_HAND.INDEXFINGER.index"
            :saveDisabled="imageData === null"
            @accept="onAccept"
            @scan="onScan"
            @replace="onReplace"
            @imageCaptured="onImageCaptured"
          />
        </section>
      </template>
    </DefaultContainer>
</template>

<script>
import { FINGERS_RIGHT_HAND, HANDS } from '@/constants/constants'
import { MAIN_PAGE_ROUTE_NAME } from '@/router/routes'
import DefaultContainer from '@/components/DefaultContainer'
import Hand from '@/components/Hand'
import Header from '@/components/Header'
import InfoBox from '@/components/InfoBox'
import ScanningResults from '@/components/ScanningResults'
import fingerprintReader from '@/composition/fingerprintReader'

export default {
  name: 'DefaultScanning',
  components: {
    DefaultContainer,
    Hand,
    Header,
    InfoBox,
    ScanningResults
  },
  setup () {
    const { captureFingerprint, resetFingerprint, stopCapture } = fingerprintReader()

    return {
      captureFingerprint,
      resetFingerprint,
      stopCapture
    }
  },
  data () {
    return {
      FINGERS_RIGHT_HAND,
      HANDS,
      isImageCaptured: false,
      loading: false,
      status: 'initial'
    }
  },
  mounted () {
    this.captureFingerprint()
  },
  methods: {
    onImageCaptured (param) {
      this.isImageCaptured = param
    },
    goBack () {
      this.isImageCaptured ? this.$refs.scanningResultsComponent.onScan() : this.$router.push({ name: MAIN_PAGE_ROUTE_NAME })
    },
    resetStatus () {
      this.status = 'initial'
    },
    advanceStatus () {
      this.status = 'finished'
    },
    onAccept () {
    },
    onScan () {
      this.stopCapture()
      this.isImageCaptured = false
      this.captureFingerprint()
    },
    onReplace () {
    }
  }
}
</script>
<style lang="scss" scoped>
.he-ui-scanning-section {
  margin-top: 100px;
}
</style>
