<template>
  <div class="he-ui-scanning-results bg-white d-flex align-items-center flex-column">
    <div class="he-ui-preview-container">
      <preview
        class="he-ui-preview"
        :image="manageFingerprintImage"
      />
    </div>
    <div class="he-ui-scanning-buttons-container d-flex flex-column align-items-center">
      <div class="he-ui-font-regular mb-3"
        v-if="!isEditPage"
      >
        {{ getPrimaryLabel }}
      </div>
      <Button
        v-if="!isAnyFingerprintResultImage"
        class="mb-3"
        template="blue"
        rounded
        uppercase
        :text="$t('main.scan')"
        :onClick="onScan" />
      <div class="he-ui-font-regular mt-4 mb-4 px-4"
        v-if="!isEditPage"
      >
        {{ getSecondaryLabel }}
      </div>
      <div class="he-ui-font-regular mt-4 mb-4 px-4"
        v-if="isEditPage && isAnyFingerprintResultImage"
      >
        {{ getEditPageLabel }}
      </div>
      <div class="d-flex gap-3">
        <Button
          v-if="!isEditPage"
          template="white"
          rounded
          uppercase
          :text="isAnyFingerprintResultImage ? $t('main.cancel') : $t('main.manual')"
          :onClick="isAnyFingerprintResultImage ? onReplace : navigateToManualScan" />
        <Button
          v-if="isEditPage"
          template='white'
          rounded
          uppercase
          :text="$t('main.cancel')"
          :onClick="onCancel"
        />
        <Button
          v-if="isAnyFingerprintResultImage"
          class="ms-4"
          template="blue"
          rounded
          uppercase
          :disabled="disableAcceptButton"
          :text="$t('main.accept')"
          :onClick="onAccept" />
      </div>
    </div>
    <hr>
    <div class="he-ui-info-scanning-container">
       <info-box
        :status="validateStatus"
        :hand="hand"
        :finger="finger"
       />
    </div>
  </div>
  <portal to="modal-place">
    <Scanning
      v-if="loading"
      class="he-ui-scanning-modal"/>
  </portal>
  <portal to="modal-place">
    <SuccesModal
      v-if="showSuccessModal"
      :patientId="patientId"
      :isNewPatient="onRegistering"
      :isAlreadyRegistered="isAlreadyRegisteredPatient"
      showButton="true"
      :message="$t('main.accept')"
      :onClicked="closeSuccessModal"/>
  </portal>
  <portal to="modal-place">
    <ErrorModal
      v-if="showErrorModal"
      :title="$t('main.error')"
      :subtitle="$t('main.fingerprint_not_found')"
      image="patient-error"
      :buttons="manageErrorModalButtons"
      @close="() => showModal = false"/>
  </portal>
  <portal to="modal-place">
    <AlreadyExistsModal
      v-if="showAlreadyRegisteredFingerprintModal"
      :onClicked="closeAlreadyExistsModal"
      @close="() => showAlreadyRegisteredFingerprintModal = false"/>
  </portal>
  <portal to='modal-place'>
    <SensorNotFound
      v-if="showSensorSNNotRegistered"
      :title="$t('main.error_connection')"
      :subtitle="$t('main.sensor_sn_not_registered')"
      image="sensor_not_found"
      :button="{text: $t('main.accept'), handler: this.closeSensorSNNotRegistered}"
    />
  </portal>
    <portal to="modal-place">
    <LowQualityModal
      v-if="isLowQuality && isEditPage"
      :onClicked="onAcceptLowQuality"
    />
  </portal>
</template>

<script>
import { ERR_CODE_PATIENT_ALREADY_REGISTERED, ERR_CODE_SENSOR_SN_NOT_REGISTERED, FINGERS_DATA, FINGERS_RIGHT_HAND, HANDS, HHID, IS_NEW_PATIENT } from '@/constants/constants'
import { MAIN_PAGE_ROUTE_NAME, SCAN_MULTIFINGER_PAGE_ROUTE_NAME } from '@/router/routes'
import { mapActions, mapMutations } from 'vuex'
import AlreadyExistsModal from '@/components/AlreadyExistsModal'
import Button from '@/components/MainButton'
import ErrorModal from '@/components/ErrorModal'
import InfoBox from '@/components/InfoBox'
import LowQualityModal from '@/components/LowQualityModal'
import Preview from '@/components/Preview'
import Scanning from '@/components/Scanning'
import SensorNotFound from '@/components/SensorNotFound'
import SuccesModal from '@/components/SuccesModal'
import fingerprintReader from '@/composition/fingerprintReader'
import mixins from '@/composition/mixins'

export default {
  components: {
    AlreadyExistsModal,
    Button,
    ErrorModal,
    InfoBox,
    LowQualityModal,
    Preview,
    Scanning,
    SensorNotFound,
    SuccesModal
  },
  props: {
    onMultipleFingersPage: {
      type: Boolean,
      default: false
    },
    onRegistering: {
      type: Boolean,
      default: false
    },
    hand: {
      type: Number,
      default: 0
    },
    finger: {
      type: Number,
      default: 0
    },
    fingerprintImage: {
      type: String,
      default: null
    },
    fingerprintScore: {
      type: Number,
      default: null
    },
    saveDisabled: {
      type: Boolean,
      default: false
    },
    isEditPage: {
      type: Boolean,
      default: false
    },
    isReplacing: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const { clean, getSerialNumber, hasResultImage, imageData, isLowQuality, resetFingerprint, scoreQuality, stopCapture } = fingerprintReader()
    const { getIdTenant, sendDataToParentClient } = mixins()

    return {
      clean,
      getIdTenant,
      getSerialNumber,
      hasResultImage,
      imageData,
      isLowQuality,
      resetFingerprint,
      sendDataToParentClient,
      scoreQuality,
      stopCapture
    }
  },
  data () {
    return {
      FINGERS_RIGHT_HAND,
      HANDS,
      fingersData: [], // backSavedFingers
      loading: false,
      isAlreadyRegisteredPatient: false,
      patientId: localStorage.getItem(HHID) ? localStorage.getItem(HHID) : null,
      showAlreadyRegisteredFingerprintModal: false,
      showErrorModal: false,
      showSensorSNNotRegistered: false,
      showSuccessModal: false,
      OK_MESSAGE: 'OK'
    }
  },
  computed: {
    getPrimaryLabel () {
      return !this.isAnyFingerprintResultImage ? this.$t('main.identify_patient_scanning_title') + ':' : ''
    },
    getSecondaryLabel () {
      return !this.isAnyFingerprintResultImage
        ? this.$t('main.if_not_possible') + ':'
        : this.isReplacing
          ? this.$t('main.previously_stored_fingerprint_replace_confirmation')
          : this.$t('main.is_fingerprint_valid')
    },
    getEditPageLabel () {
      return this.fingerInSaved(this.finger)
        ? this.$t('main.previously_stored_fingerprint_replace_confirmation')
        : this.$t('main.is_fingerprint_valid')
    },
    manageFingerprintImage () {
      return this.isPropFingerprintImage ? this.fingerprintImage : this.imageData
    },
    manageFingerprintScore () {
      return this.isPropFingerprintImage ? this.fingerprintScore : this.scoreQuality
    },
    manageErrorModalButtons () {
      return (this.onMultipleFingersPage)
        ? [
          { text: this.$t('main.try_again'), template: 'white', handler: () => { this.showErrorModal = false; this.onScan() } },
          { text: this.$t('main.new_patient'), template: 'blue', handler: this.onNewPatient }
        ]
        : [{ text: this.$t('main.try_again'), template: 'blue', handler: this.onTryAgain }]
    },
    validateStatus () {
      return !this.isAnyFingerprintResultImage ? 'initial' : 'finished'
    },
    isResultImage () {
      return this.hasResultImage
    },
    isPropFingerprintImage () {
      return this.fingerprintImage !== null
    },
    isAnyFingerprintResultImage () {
      return this.isResultImage || this.isPropFingerprintImage
    },
    disableAcceptButton () {
      if ((!this.saveDisabled) && !(this.isLowQuality && localStorage.getItem(IS_NEW_PATIENT))) {
        this.$emit('imageCaptured', true)
      }
      return (this.saveDisabled) || (this.isLowQuality && localStorage.getItem(IS_NEW_PATIENT))
    }
  },
  mounted () {
    if (this.isEditPage) {
      this.onScan()
    }
  },
  methods: {
    ...mapActions([
      'checkIfNotExistingFingerprint',
      'createPatient',
      'loginPatient'
    ]),
    ...mapMutations([
      'resetPatientId'
    ]),
    navigateToManualScan () {
      this.$router.push({
        name: SCAN_MULTIFINGER_PAGE_ROUTE_NAME
      })
    },
    navigateToOwnMultifingerReset (savedFingers = null) {
      this.$emit('navigateToMultifinger', savedFingers)
    },
    onScan () {
      this.resetFingerprint()
      this.$emit('scan')
    },
    onCancel () {
      this.resetFingerprint()
      this.$emit('cancel')
    },
    onAccept () {
      if (this.onRegistering) {
        this.onRegisterFingerprint()
      } else {
        this.loading = true
        const fingerprintPicture = this.manageFingerprintImage
        const fingerprintSentScore = this.manageFingerprintScore
        this.getSerialNumber()
          .then((response) => {
            const data = {
              idSensor: response, // @TODO update to idSensor (renamed on endpoint)
              idFinger: this.finger,
              idTenant: this.getIdTenant(),
              imageBase64: this.clean(fingerprintPicture),
              score: fingerprintSentScore
            }
            this.loginPatient(data)
              .then((response) => {
                this.patientId = response.HHID
                this.sendDataToParentClient(this.patientId)
                this.showSuccessModal = true
              })
              .catch((e) => {
                if (e.response.data.errorCode === ERR_CODE_SENSOR_SN_NOT_REGISTERED) {
                  this.showSensorSNNotRegistered = true
                } else {
                  this.showErrorModal = true
                }
              })
              .finally(() => {
                this.loading = false
              })
          })
          .catch((e) => {
            console.log('Error capturing sensor serial number', e)
          })
      }
    },
    onReplace () {
      this.resetFingerprint()
      this.$emit('replace', this.hand, this.finger)
    },
    onTryAgain () {
      this.$router.push({
        name: SCAN_MULTIFINGER_PAGE_ROUTE_NAME
      })
    },
    onRegisterFingerprint () {
      this.loading = true
      this.getSerialNumber()
        .then((response) => {
          const data = {
            idSensor: response,
            idFinger: this.finger,
            idTenant: this.getIdTenant(),
            imageBase64: this.clean(this.manageFingerprintImage),
            score: this.manageFingerprintScore
          }
          this.checkIfNotExistingFingerprint(data)
            .then((response) => {
              this.loading = true
              this.saveFingerprintForRegister(response)
              this.retrieveFingerprintsForPatient()
            })
            .catch((e) => {
              // this.showErrorModal = true
              switch (e.response.data.errorCode) {
                case ERR_CODE_PATIENT_ALREADY_REGISTERED:
                  this.patientId = e.response.data.optionData
                  this.isAlreadyRegisteredPatient = true
                  this.sendDataToParentClient(this.patientId)
                  this.showSuccessModal = true
                  break
                case ERR_CODE_SENSOR_SN_NOT_REGISTERED:
                  this.showSensorSNNotRegistered = true
                  break
                default:
                  throw Error(`${e.name}: Error checking fingerprint`)
              }
            })
            .finally(() => {
              this.loading = false
            })
        })
        .catch((e) => {
          this.showErrorModal = true
          this.loading = false
          console.log('error', e)
        })
    },
    saveFingerprintForRegister (data) {
      if (this.fingerInSaved(this.finger)) {
        this.replaceFingerInSaved(this.finger, data)
      } else {
        this.pushFingerToSaved(this.finger, data)
      }
      this.retrieveFingerprintsForPatient()
    },
    fingerInSaved (finger) {
      return localStorage.getItem(FINGERS_DATA) !== null
        ? JSON.parse(localStorage.getItem(FINGERS_DATA)).map(element => element.idFinger).includes(finger)
        : false
    },
    replaceFingerInSaved (idFinger, data) {
      const savedFingers = JSON.parse(localStorage.getItem(FINGERS_DATA))
      savedFingers.every(obj => {
        if (obj.idFinger === idFinger) {
          obj.data = data
          return false
        }
        return true
      })
      localStorage.setItem(FINGERS_DATA, JSON.stringify(savedFingers))
    },
    pushFingerToSaved (idFinger, data) {
      const newAddedFinger = {
        idFinger: idFinger,
        data: data
      }
      const savedFingers = JSON.parse(localStorage.getItem(FINGERS_DATA)) || []
      savedFingers.push(newAddedFinger)
      localStorage.setItem(FINGERS_DATA, JSON.stringify(savedFingers))
    },
    retrieveFingerprintsForPatient () {
      if (this.onRegistering) {
        this.fingersData = JSON.parse(localStorage.getItem(FINGERS_DATA)).map(i => i.idFinger)
        this.isEditPage ? this.$emit('cancel') : this.navigateToOwnMultifingerReset(this.fingersData)
      }
    },
    onNewPatient () {
      this.showErrorModal = false
      localStorage.setItem(IS_NEW_PATIENT, true)
      this.retrieveFingerprintsForPatient()
      this.navigateToOwnMultifingerReset(this.fingersData)
    },
    closeSuccessModal () {
      this.canLeavePage = true
      this.$emit('canLeave')
      this.showLeavingModal = false
      this.showSuccessModal = false
      this.resetPatientId()
      this.$router.push({
        name: MAIN_PAGE_ROUTE_NAME
      })
    },
    closeAlreadyExistsModal () {
      this.showAlreadyRegisteredFingerprintModal = false
      this.retrieveFingerprintsForPatient()
    },
    closeSensorSNNotRegistered () {
      this.showSensorSNNotRegistered = false
    },
    onAcceptLowQuality () {
      this.onScan()
    }
  }
}
</script>

<style lang="scss">
.he-ui-fingerprint-preview {
  @media (max-height: 810px) {
    width: 242px !important;
    height: 242px !important;
  }
}
</style>
<style lang="scss" scoped>
hr {
  color: $grey-dark-color;
  width: 100%;
  margin-bottom: 0px !important;
}
.he-ui-preview-container {
  width: 242px;
  height: 242px;
  position: relative;
  top: -121px;
}
.he-ui-scanning-buttons-container {
  position: relative;
  top: -60px;
}
.he-ui-fingerprint-preview {
  width: 242px !important;
  height: 242px !important;
  @media (max-height: 810px) {
    width: 100px !important;
    height: 100px !important;
    background: greenyellow;
  }
}
.he-ui-info-scanning-container {
  margin: 40px 0px;
}
.he-ui-scanning-results {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  margin-right: 5%;
}
</style>
