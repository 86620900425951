<template>
  <div
    :class="{'hands--displayed': isNewPatient}"
    class="he-ui-core-hand-img d-flex align-items-center justify-content-between flex-column">
    <div class="position-relative he-ui-hand-container mt-5"
      :class="{'he-ui-each-hand-container': bothHandsTogether}"
    >
      <div
        v-for="fingerprint in getFingerPrints"
        :key="fingerprint.index"
        class="position-absolute he-ui-core-hand-fingerprint-img"
        :class="{'he-ui-minor-fingerprints': bothHandsTogether}"
        :style="{'top': fingerprint.top, 'left': fingerprint.left}">
        <img v-if="isShowFingerprint(fingerprint.index)"
          :src="getFingerprintImage(fingerprint.index)"
          alt="selected_finger_hand"
        />
      </div>
        <img
          :src="getSelectedHand"
          :class="{'he-ui-both-hands': bothHandsTogether}"
          alt="selected_finger_hand" />
    </div>
      <div
        v-if="isNewPatient"
        class="he-ui-hands-status-wrapper mt-4 d-flex">
      </div>
  </div>
  <div
    v-if="isNewPatient"
    class="he-ui-mini-hands-wrapper d-flex">
    <div
      v-for="(n, finger) in 5"
      :key="finger"
      class="he-ui-mini-hand me-2">
      <img :src="getHands(HANDS.LEFT.index, finger, savedFingersData)" alt="" />
    </div>
    <div
      v-for="finger in [...Array(10).keys()].slice(5).reverse()"
      :key="finger"
      class="he-ui-mini-hand me-2">
      <img :src="getHands(HANDS.RIGHT.index, finger, savedFingersData)" alt="" />
    </div>
  </div>
</template>

<script>
import { HANDS, LAST_INDEX_RIGHT_HAND } from '@/constants/constants'
import handsCompositionFunction from '@/composition/handsCompositionFunction'

export default {
  props: {
    selectedFinger: {
      type: Number,
      require: true
    },
    selectedHand: {
      type: Number,
      require: true
    },
    showNumberOfFingers: {
      type: Boolean,
      default: true
    },
    showAllFingerprints: {
      type: Boolean,
      default: true
    },
    showNotActiveFingerprints: {
      type: Boolean,
      default: true
    },
    isNewPatient: {
      type: Boolean,
      default: false
    },
    savedFingers: {
      type: Array,
      default: () => []
    },
    bothHandsTogether: {
      type: Boolean,
      deafult: false
    }
  },
  setup () {
    const { getHands } = handsCompositionFunction()

    return {
      getHands
    }
  },
  data () {
    return {
      fingerprints: [
        [
          { top: '31%', left: '-1%', index: 0 },
          { top: '-7%', left: '25%', index: 1 },
          { top: '-15%', left: '46%', index: 2 },
          { top: '-10%', left: '66%', index: 3 },
          { top: '6%', left: '84%', index: 4 }
        ],
        [
          { top: '6%', left: '-1%', index: 5 },
          { top: '-10%', left: '17%', index: 6 },
          { top: '-15%', left: '37%', index: 7 },
          { top: '-7%', left: '57%', index: 8 },
          { top: '31%', left: '86%', index: 9 }
        ]
      ],
      finger: this.selectedFinger,
      hand: this.selectedHand,
      HANDS,
      savedFingersData: this.savedFingers
    }
  },
  computed: {
    getSelectedHand () {
      if (this.showNumberOfFingers) {
        const selectedHand = this.hand
        return require('@/assets/hands/hand-' + selectedHand + '.svg')
      } else {
        switch (this.hand) {
          case 0:
            return require('@/assets/hands/left-hand.png')
          case 1:
            return require('@/assets/hands/right-hand.png')
          default:
            return require('@/assets/hands/right_hand_without_numbers.png')
        }
      }
    },
    getFingerPrints () {
      if (this.showAllFingerprints) return this.fingerprints[this.hand]
      return [this.fingerprints[HANDS.RIGHT.index][3]]
    }
  },
  watch: {
    selectedFinger (value) {
      this.finger = value
    },
    selectedHand (value) {
      this.hand = value
    },
    savedFingers (newVal) {
      this.savedFingersData = newVal
    }
  },
  methods: {
    getFingerprintImage (index) {
      const multiSelectorIndex = this.selectedHand === 1 ? LAST_INDEX_RIGHT_HAND - index : index
      if (index === this.finger) {
        return require('@/assets/hands/fingerprint-selected.png')
      } else if (this.savedFingers.includes(multiSelectorIndex)) {
        return require('@/assets/hands/fingerprint-saved.png')
      } else {
        return require('@/assets/hands/fingerprint.png')
      }
    },
    isShowFingerprint (fingerprintIndex) {
      return this.showNotActiveFingerprints || this.finger === fingerprintIndex
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-core-hand-fingerprint-img {
  width: 60px;
  height: 60px;
  @media (max-width: 1180px) {
    width: 40px;
    height: 40px;
  }
  @media (max-height: 780px) {
    width: 40px;
    height: 40px;
  }
}
.he-ui-both-hands {
  position: relative;
  top: -24px;
}
.he-ui-minor-fingerprints {
  width: 40px;
  height: 40px;
}
.he-ui-hand-container {
  margin: 0px 20px;
  @media (max-height: 780px) {
    height: 330px;
  }
}
.he-ui-each-hand-container {
  height: 310px;
}
.he-ui-mini-hand {
  width: 45px;
  height: 60px;
  img {
    max-width: 100%;
  }
  &:nth-child(5) {
    margin-right: 30px!important;
  }
}
</style>
